import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const TheContainer = () =>
    import ('@/containers/TheContainer')

// Views
const Dashboard = () =>
    import ('@/views/Dashboard')
const setting = () =>
    import ('@/views/setting/Form')
const update = () =>
    import ('@/views/update/Form')
const changePass = () =>
    import ('@/views/change-pass/Form')

// Views - Pages
const Page404 = () =>
    import ('@/views/pages/Page404')
const Page500 = () =>
    import ('@/views/pages/Page500')
const Login = () =>
    import ('@/views/pages/Login')
const Register = () =>
    import ('@/views/pages/Register')

Vue.use(Router)

const routes = [{
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        component: TheContainer,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'quizs',
                redirect: '/base/cards',
                name: 'Users',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'categories',
                        name: 'Popovers',
                        component: () => import ('@/views/quizs/category/List'),
                        meta: {
                            requiresAuth: false,
                        }
                    },
                    {
                        path: 'deposits',
                        name: 'Popovers',
                        component: () => import ('@/views/quizs/sub-category/List'),
                        meta: {
                            requiresAuth: false,
                        }
                    },
                    {
                        path: 'expenses',
                        name: 'Popovers',
                        component: () => import ('@/views/quizs/expense/List'),
                        meta: {
                            requiresAuth: false,
                        }
                    }
                ]
            },
            {
                path: 'general-setting',
                redirect: '/base/cards',
                name: 'General Setting',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: 'setting',
                        name: 'Popovers',
                        component: setting,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'app-update',
                        name: 'Popovers',
                        component: update,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'change-password',
                        name: 'changePassword',
                        component: changePass,
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'app-management',
                name: 'appUsers',
                component: {
                    render(c) { return c('router-view') }
                },
                children: [ 
                    {
                        path: 'users',
                        name: 'user-list',
                        component: () => import('../views/customer/List'),
                        meta: {
                            requiresAuth: false,
                            name: 'user-list'
                        }
                    },
                    {
                        path: 'admin-users',
                        name: 'user-list',
                        component: () => import('../views/user/List'),
                        meta: {
                            requiresAuth: false,
                            name: 'user-list'
                        }
                    },
                  {
                    path: 'roles',
                    name: 'role-list',
                    component: () => import('../views/role/List'),
                    meta: {
                      requiresAuth: false,
                      name: 'role-list'
                    }
                  },
                  {
                    path: 'permissions',
                    name: 'permission-list',
                    component: () => import('../views/permission/List'),
                    meta: {
                      requiresAuth: false,
                      name: 'permission-list'
                    }
                  }
                ]
            }
        ]
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: 'category',
                name: 'category',
                component: () => import('../views/quizs/category/PaymentList'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '404',
                name: 'Page404',
                component: Page404,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '500',
                name: 'Page500',
                component: Page500,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
                meta: {
                    requiresAuth: false
                }
            }
        ]
    }
]


const router = new Router({
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/pages/login')
    } else {
        next()
    }
})

export default router